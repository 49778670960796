import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { showToast } from '@/lib/toast';
import { contactDealer } from '@/services/dealer';
import { ListingEmailInfo } from '@/types/leads';
import { useRouter } from 'next/router';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

interface DealerContactContextType {
  contactDealer: (params: {
    body: ListingEmailInfo;
    onSuccess?: () => Promise<void>;
  }) => Promise<void>;
}

export const DealerContactContext =
  createContext<DealerContactContextType | null>(null);

export const DealerContactProvider: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { handleActionTracking } = useAnalytics();

  const messageDealer: DealerContactContextType['contactDealer'] = useCallback(
    async ({ body, onSuccess }) => {
      handleActionTracking(GOOGLE_TRACK_INFO.sendMessageButton);
      const response = await contactDealer(body);
      if (response.ok) {
        showToast('Sent! the dealer will be in touch soon.', {
          type: 'success',
          onClick: () => router.push('/my-evs'),
        });
        handleActionTracking(GOOGLE_TRACK_INFO.sendMessageSuccess);
        if (onSuccess) {
          await onSuccess();
        }
      } else {
        showToast(
          'There was a problem contacting the dealer. Please try again.',
          { type: 'error' }
        );
        handleActionTracking(GOOGLE_TRACK_INFO.sendMessageFail);
      }
    },
    [router, handleActionTracking]
  );

  const contextValue = useMemo(
    () => ({ contactDealer: messageDealer }),
    [messageDealer]
  );

  return (
    <DealerContactContext.Provider value={contextValue}>
      {children}
    </DealerContactContext.Provider>
  );
};

export const useDealer = () => {
  const context = useContext(DealerContactContext);
  if (!context) {
    throw new Error('useDealer must be used within a DealerContactProvider');
  }
  return context;
};
