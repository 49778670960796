import { Avatar } from '@/components/atoms/Avatar';
import Link from '@/components/atoms/Link';
import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import TextButton from '@/components/atoms/legacy/TextButton';
import Logo from '@/components/molecules/Logo';
import { CarOutline } from '@/components/template/MyAccountLandingTemplate/CarOutlineIcon';
import { useAuth } from '@/hooks/useAuth';
import useWindowSize from '@/hooks/useWindowSize';
import { AFTER_LOGIN_REDIRECT_KEY, GOOGLE_TRACK_INFO } from '@/lib/constants';
import { getInitials } from '@/lib/user';
import { Component } from '@/types/interfaces';
import {
  Bars3BottomRightIcon,
  UserCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useRouter } from 'next/router';
import React from 'react';
import { DesktopHeaderLinks } from '../DesktopHeaderLinks/DesktopHeaderLinks';
import { MobileFooterLinks } from '../MobileFooterLinks/MobileFooterLink';
import { MobileHeaderLinks } from '../MobileHeaderLinks/MobileHeaderLinks';

const tailwind = {
  panel: [
    'absolute',
    'l:relative',
    'flex',
    'flex-col-reverse',
    'l:flex-row',
    'l:items-center',
    'l:justify-between',
    'l:h-auto',
    'gap-xl',
    'pb-[20px]',
    'l:p-0',
    'left-0',
    'right-0',
    'top-full',
    'z-40',
    'bg-white',
    'l:bg-transparent',
    'l:w-full',
    'overflow-auto',
  ].join(' '),
};

const NavBar: React.FC<Component> = ({ className = '', ...props }) => {
  const { user, signOut } = useAuth();
  const router = useRouter();
  const [panelOpen, setPanelOpen] = React.useState(false);
  const [fadeStyle, setFadeStyle] = React.useState('hidden');
  const { isDesktop } = useWindowSize();
  const userInitials = getInitials(user?.fullName);
  React.useEffect(() => {
    if (panelOpen) {
      document.documentElement.classList.add('overflow-hidden');
    }
    return () => {
      document.documentElement.classList.remove('overflow-hidden');
    };
  }, [panelOpen]);
  React.useEffect(() => {
    const handleRouteChange = () => {
      setPanelOpen(false);
      setFadeStyle('fadeOut');
      setTimeout(() => {
        setFadeStyle('hidden');
      }, 300);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <header className={`flex items-center py-m ${className}`} {...props}>
      <div className="flex-grow l:mr-[80px]">
        <Link className="" href="/" aria-label="home">
          <Logo className={`relative w-[92px] flex-none l:w-[126px]`} />
        </Link>
      </div>

      <div className="flex flex-shrink-0 items-center gap-l lg:order-3">
        <Link
          className="flex rounded-t-xsmall border-b-[2px] border-white px-xs py-s hover:border-b-brandElectric/80 hover:bg-neutral-100 l:hidden"
          activeClassName="!text-brandMidnight !border-b-brandElectric"
          href="/search"
          aria-label="search"
        >
          {router.asPath === '/search' ? (
            <Icon
              height={24}
              width={24}
              iconId={IconIds.Car}
              strokeColor="#FFFFFF"
              fillColor="#010044"
            />
          ) : (
            <CarOutline className="text-neutral-800" />
          )}
        </Link>
        {user ? (
          <Link
            className="hidden items-center rounded-t-xsmall border-b-[2px] border-white px-xs py-s hover:border-b-brandElectric/80 hover:bg-neutral-100 l:flex"
            activeClassName="!border-b-brandElectric"
            href="/my-evs"
          >
            <Avatar
              size="small"
              fallback={userInitials}
              imageIconSrc={user.picture}
            />
          </Link>
        ) : (
          <div className="flex flex-row gap-s">
            <Link
              className="group rounded-xsmall p-s text-body1Regular text-neutral-800 hover:bg-neutral-100 focus:bg-neutral-100"
              href="/login"
              analyticsEvent={GOOGLE_TRACK_INFO.loginDirectionButton}
              onClick={() =>
                sessionStorage.setItem(AFTER_LOGIN_REDIRECT_KEY, router.asPath)
              }
            >
              <div className="flex items-center gap-s">
                <UserCircleIcon
                  width={24}
                  height={24}
                  className="focus:text-brandMidnight/80 group-hover:text-brandMidnight/80"
                />
                <span className="focus:text-brandMidnight/80 group-hover:text-brandMidnight/80">
                  Login
                </span>
              </div>
            </Link>
          </div>
        )}
        <TextButton
          className={`ml-auto flex items-center justify-center hover:bg-neutral-100 ${
            panelOpen ? 'border-b-2 border-b-brandElectric' : ''
          } px-xs py-s l:hidden`}
          onClick={() =>
            setPanelOpen((prev) => {
              if (!prev) {
                setFadeStyle('fadeIn');
              } else {
                setFadeStyle('fadeOut');
                setTimeout(() => {
                  setFadeStyle('hidden');
                }, 300);
              }
              return !prev;
            })
          }
          aria-label={
            panelOpen ? 'Close navigation panel' : 'Open navigation panel'
          }
        >
          {panelOpen ? (
            <XMarkIcon width={24} height={24} className="text-brandMidnight" />
          ) : (
            <Bars3BottomRightIcon
              width={24}
              height={24}
              className="text-neutral-800"
            />
          )}
        </TextButton>
      </div>
      <section className={`${tailwind.panel} ${isDesktop ? '' : fadeStyle} `}>
        <section
          className={`order-2 hidden basis-4/5 px-l max-l:flex-col max-l:items-center max-l:space-y-xl m:px-[40px] l:order-1 l:block l:space-x-[40px]`}
        >
          <div className="flex-row gap-[40px] l:flex">
            <DesktopHeaderLinks />
          </div>
        </section>
        <section className="h-screen gap-l overflow-auto l:hidden">
          {panelOpen && <MobileHeaderLinks user={user} signOut={signOut} />}
          {!isDesktop && panelOpen && <MobileFooterLinks router={router} />}
        </section>
      </section>
    </header>
  );
};

export default NavBar;
