import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { useAuth } from '@/hooks/useAuth';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { getPublicIpAddress } from '@/lib/ipApi';
import { showToast } from '@/lib/toast';
import {
  MinimalSavedVehicle,
  SavedVehicles,
  SaveVehicleRequest,
  StoreField,
} from '@/lib/user';
import { useRouter } from 'next/router';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export const initialSavedVehicles: SavedVehicles = {
  build: {},
  model_level_page: {},
  listing: {},
  incomplete_build: {},
};

interface SaveVehicleOptions {
  onSuccess?: () => void;
  hideToast?: boolean;
}

interface SavedVehiclesContextValue {
  loading: boolean;
  saveVehicle: (
    storeField: StoreField,
    info: SaveVehicleRequest,
    options?: SaveVehicleOptions
  ) => Promise<void>;
  unsaveVehicle: (
    storeField: StoreField,
    itemId: string | number,
    onSuccess: () => void
  ) => Promise<void>;
}

interface UseSavedVehiclesResult
  extends Pick<SavedVehiclesContextValue, 'loading'> {
  saved: boolean;
  saveVehicle: (
    id: string | number,
    options?: SaveVehicleOptions
  ) => Promise<void>;
}

type UseSavedVehiclesConfig = {
  storeField: StoreField;
  initiallySaved: boolean;
  infoToSave: SaveVehicleRequest;
};

export const SavedVehiclesContext =
  createContext<SavedVehiclesContextValue | null>(null);

export const SavedVehiclesProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const unsaveVehicle = useCallback(
    async (
      storeField: StoreField,
      itemId: string | number,
      onSuccess: () => void
    ) => {
      setLoading(true);
      try {
        await fetch(
          `/api/user/user_store/${itemId}?store_field=${storeField}`,
          {
            method: 'DELETE',
          }
        );
        onSuccess();
        showToast('Vehicle removed from your profile', { type: 'info' });
      } catch (err) {
        showToast('Failed to remove vehicle', { type: 'error' });
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const saveVehicle = useCallback(
    async (
      storeField: StoreField,
      { id, ...rest }: SaveVehicleRequest,
      options?: SaveVehicleOptions
    ) => {
      const hideToast = !!options?.hideToast;
      setLoading(true);
      const shouldShowToast = !hideToast && storeField !== 'incomplete_build';
      try {
        const body: Record<string, unknown> = { id, ...rest };
        const shouldFetchIp =
          storeField === 'incomplete_build' || storeField === 'build';
        if (shouldFetchIp) {
          const ip = await getPublicIpAddress();
          body.ip_address = ip;
        }
        await fetch(`/api/user/user_store?store_field=${storeField}`, {
          method: 'PUT',
          body: JSON.stringify(body),
        });
        if (options?.onSuccess) {
          options.onSuccess();
        }
        if (shouldShowToast) {
          showToast('Vehicle saved to your profile', {
            type: 'success',
            onClick: () => router.push('/my-evs'),
          });
        }
      } catch (error) {
        if (shouldShowToast) {
          showToast('Failed to save vehicle', {
            type: 'error',
            onRetry: () => saveVehicle(storeField, { id, ...rest }, options),
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [router]
  );

  /**
   * Explanation for wrapping a context value in `useMemo`:
   * https://react.dev/reference/react/useContext#optimizing-re-renders-when-passing-objects-and-functions
   */
  const contextValue = useMemo(
    () => ({
      loading,
      saveVehicle,
      unsaveVehicle,
    }),
    [loading, saveVehicle, unsaveVehicle]
  );

  return (
    <SavedVehiclesContext.Provider value={contextValue}>
      {children}
    </SavedVehiclesContext.Provider>
  );
};

export const useSavedVehicles: (
  config: UseSavedVehiclesConfig
) => UseSavedVehiclesResult = (config: UseSavedVehiclesConfig) => {
  const context = useContext(SavedVehiclesContext);

  if (!context) {
    throw new Error(
      'useSavedVehicles must be used within a SavedVehiclesProvider'
    );
  }

  const { handleActionTracking } = useAnalytics();
  const { user } = useAuth();

  const {
    storeField,
    initiallySaved,
    infoToSave: { make, model, year, ...rest },
  } = config;
  const userClickedOnHeartButton =
    storeField === 'listing' || storeField === 'model_level_page';

  const [saved, setSaved] = useState(initiallySaved);
  const checkedForSessionStorageId = useRef(false);

  const { saveVehicle, unsaveVehicle, loading } = context;

  const handleSaveVehicle = useCallback(
    async (vehicleId: string | number, options?: SaveVehicleOptions) => {
      if (userClickedOnHeartButton) {
        handleActionTracking({
          ...GOOGLE_TRACK_INFO.clickHeartButton,
          customProperties: {
            internalId: `${vehicleId}`,
            type: storeField,
            make,
            model,
            year: `${year}`,
            isSaved: `${saved}`,
          },
        });
      }
      if (user) {
        if (saved) {
          await unsaveVehicle(storeField, vehicleId, () => setSaved(false));
        } else {
          await saveVehicle(
            storeField,
            { make, model, year, ...rest },
            { ...options, onSuccess: () => setSaved(true) }
          );
        }
      } else {
        sessionStorage.setItem('saveVehicleId', `${vehicleId}`);
      }
    },
    [
      saveVehicle,
      make,
      model,
      year,
      handleActionTracking,
      unsaveVehicle,
      storeField,
      saved,
      rest,
      user,
      userClickedOnHeartButton,
    ]
  );

  useEffect(() => {
    if (!user && saved) {
      setSaved(false);
    }
  }, [user, saved]);

  useEffect(() => {
    const idToSave = sessionStorage.getItem('saveVehicleId') || '';
    const saveVehicleOnLoad = async () => {
      await handleSaveVehicle(rest.id);
      sessionStorage.removeItem('saveVehicleId');
    };
    const shouldNotifyUserOnSignIn = userClickedOnHeartButton;
    if (
      !checkedForSessionStorageId.current &&
      shouldNotifyUserOnSignIn &&
      user &&
      !saved &&
      rest.id === idToSave
    ) {
      checkedForSessionStorageId.current = true;
      saveVehicleOnLoad();
    }
  }, [
    storeField,
    user,
    saved,
    rest.id,
    handleSaveVehicle,
    userClickedOnHeartButton,
  ]);

  useEffect(() => {
    setSaved(initiallySaved);
  }, [initiallySaved]);

  return {
    saved,
    loading,
    saveVehicle: handleSaveVehicle,
  };
};

export const useSession = () => {
  const context = useContext(SavedVehiclesContext);
  if (!context) {
    throw new Error('useSession must be used within a SavedVehiclesProvider');
  }
  const { user } = useAuth();
  const { saveVehicle } = context;
  const saveSession = useCallback(
    async (sessionInfo: MinimalSavedVehicle) => {
      if (user) {
        await saveVehicle('incomplete_build', sessionInfo);
      }
    },
    [saveVehicle, user]
  );
  return { saveSession };
};
